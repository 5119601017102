precision mediump float;

uniform vec3 uColor;
uniform float uAspect;

varying vec2 vUv;

#include common/color

void main() {
    vec2 uv;
    float radius;
    if (uAspect > 1.0) {
        uv = vUv * vec2(uAspect, 1.0);
        radius = distance(uv, vec2(uAspect * 0.5, 0.5));
    }
    else {
        uv = vUv / vec2(1.0, uAspect);
        radius = distance(uv, vec2(0.5, 0.5 / uAspect));
    }

    float alpha = 1.0 - pow(1.0 - radius, 1.0);
    gl_FragColor = vec4(vec3(alpha) * uColor, 1.0);
}
