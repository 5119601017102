precision mediump float;

varying vec2 vUv;
varying vec2 vPUv;
varying float vDepth;

uniform sampler2D uTexture;
uniform float uContrast;
uniform float uLCrop;
uniform float uHCrop;
uniform float uExposure;
uniform float uSaturation;

uniform float uOpacity;
uniform float uDOF;

#include common/color

void main() {
    float radius = distance(vUv, vec2(0.5));
	float t = smoothstep(0.0, 0.3, 0.5 - radius);
    vec3 color = vec3(texture2D(uTexture, vPUv).rgb);

    color = toneCurve(color, uContrast, uLCrop, uHCrop, uExposure, uSaturation);

    float depthBlur = pow(10. / vDepth, uDOF);

    gl_FragColor = vec4(color, t * uOpacity * depthBlur);
}
