import * as THREE from 'three'
import { App } from '../App'

export class TouchTexture {
    constructor() {
        this.app = null

        this.ttl = 3
        this.size = 64
        this.radius = 7

        this.canvas = null
        this.ctx = null
        this.texture = null
        this.points = []

        this.rampDuration = 0
        this.rampTime = 0
        this.entropy = 0

        this.touchRect = null

        this.init()
    }

    init() {
        this.app = new App()

        this.canvas = document.createElement('canvas')
        this.canvas.width = this.canvas.height = this.size
        this.canvas.style.width = this.canvas.style.height = this.size + 'px'

        if (this.app.debug.active === true) {
            document.body.insertBefore(this.canvas, document.body.firstChild);
            this.canvas.style.position = "fixed"
            this.canvas.style.zIndex = 999
            this.canvas.style.left = "50px"
            this.canvas.style.top = "50px"
            this.canvas.style.width = this.canvas.style.height = "300px"
        }

        this.ctx = this.canvas.getContext('2d')

        this.ctx.fillStyle = 'black'
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

        this.texture = new THREE.CanvasTexture(this.canvas)
    }

    addPoint(uv, ttl) {
        if (typeof ttl !== "number") {
            ttl = this.ttl
        }
        // console.log('this.points.length :>> ', this.points.length);

        const pt = new THREE.Vector2(uv.x, 1-uv.y).multiplyScalar(this.size)
        this.points.push({x: pt.x, y: pt.y, ttl: ttl, power:1})
    }

    drawPoint(pt) {
        pt.power = pt.ttl / this.ttl

        const gradient = this.ctx.createRadialGradient(pt.x, pt.y, this.radius * 0.2, pt.x, pt.y, this.radius)

        gradient.addColorStop(0, '#000000')
        gradient.addColorStop(1, '#ffffff')

        this.ctx.beginPath()
        this.ctx.fillStyle = gradient
		this.ctx.arc(pt.x, pt.y, this.radius * pt.power, 0, Math.PI * 2)
		this.ctx.fill()
    }

    startRamp(duration) {
        this.rampDuration = duration
        this.rampTime = 0

        // Inject points with random params
        const count = 10
        for (let i = 0; i < count; i++) {
            const ttl = Math.random() * this.ttl
            this.addPoint(new THREE.Vector2(), ttl)
        }
    }

    updateRamp(delta) {
        const tn = this.rampTime / this.rampDuration
        const color = new THREE.Color().setHSL(0, 0, 1 - tn).getStyle()

        this.ctx.fillStyle = color
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

        this.rampTime += delta

        if (this.rampTime >= this.rampDuration) {
            this.rampDuration = 0
            this.rampColorFrom = null
            this.rampColorTo = null
            this.rampCanvas = null
        }
    }

    update(delta) {
        // console.log('this.points.length :>> ', this.points.length);
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

        if (this.rampDuration !== 0) {
            this.updateRamp(delta)
        }

        this.entropy = 0
        this.points.forEach((point, idx, points) => {
            this.drawPoint(point)
            this.entropy += point.power

            point.ttl -= delta
            if (point.ttl <= 0) {
                points.splice(idx, 1)
            }
        })

        // console.log('this.entropy :>> ', this.entropy);

        this.texture.needsUpdate = true
    }

    destroy() {
        this.points.length = 0

        if (this.texture !== null) {
            this.texture.dispose()
            this.texture = null
        }

        this.touchRect = null
        this.ctx = null
        this.canvas = null
        this.app = null
    }
}