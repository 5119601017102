import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import { App } from '/App'
import { EventEmitter } from '/Utils/EventEmitter'

export class Camera extends EventEmitter {
    constructor(controllable) {
        super()

        this.controllable = controllable

        this.app = null
        this.perspective = null
        this.orthographic = null
        this.group = null
        this.controls = null

        this.resizeHandlerBound = this.resizeHandler.bind(this)

        this.init()
    }

    init() {
        this.app = new App()

        this.orthographic = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.01, 1000)
        this.perspective = new THREE.PerspectiveCamera(30, this.app.renderSize.aspect, 0.1, 1000)

        this.group = new THREE.Group()
        this.group.add(this.perspective)

        if (this.controllable === true) {
            this.controls = new OrbitControls(this.perspective, this.app.canvas)
        }

        this.app.renderSize.on('resize', this.resizeHandlerBound)
    }

    resizeHandler(info) {
        this.perspective.aspect = info.aspect
        this.perspective.updateProjectionMatrix()

        this.fovWidth = this.fovHeight * info.width / info.height
    }

    destroy() {
        this.app.renderSize.off('resize')
        this.resizeHandlerBound = null

        this.group.remove(this.perspective)
        this.group = null

        this.controls.dispose()
        this.controls = null

        this.instance = null
        this.orthographic = null

        this.app = null
    }
}