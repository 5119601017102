import * as THREE from 'three'

import { FizzyParticles } from './World/FizzyParticles.js'
import { AnimationLoop } from './Utils/AnimationLoop.js'
import { RenderSize } from './Utils/RenderSize.js'
import { Camera } from './Core/Camera.js'
import { Renderer } from './Core/Renderer.js'
import { AssetManager } from './Utils/AssetManager.js'
import { Debug } from './Utils/Debug.js'
import { EventEmitter } from './Utils/EventEmitter.js'
import { ScreenScroll } from './Utils/ScreenScroll.js'

import assets from "./assets.js"


import backgroundVS from './shaders/background.vert.glsl'
import backgroundFS from './shaders/background.frag.glsl'

// Singleton app
let instance = null

export class App extends EventEmitter {
    constructor(canvas) {
        if (instance !== null) {
            return instance
        }

        super()
        instance = this

        this.canvas = canvas

        this.animationLoop = null
        this.renderSize = null
        this.camera = null

        this.scene = null
        this.renderer = null
        this.assetManager = null

        this.backgroundScene = null
        this.backgroundPlane = null

        this.sectionBackgroundTexture = null

        this.scroll = null
        this.pageSections = null

        this.ambient = null
        this.dir = null
        this.spotLight = null

        // this.newSectionSound = null

        this.fizzy1 = null
        this.fizzy2 = null
        this.fizzy3 = null
        this.fizzy4 = null

        this.pointerTimestamp = 0

        this.fovHeight = 0 // World units
        this.fovWidth = 0 // World units

        this.assetManagerReadyHandlerBound = this.assetManagerReadyHandler.bind(this)
        this.touchEntropyHandlerBound = this.touchEntropyHandler.bind(this)
        this.pointerMoveHandlerBound = this.pointerMoveHandler.bind(this)
        this.resizeHandlerBound = this.resizeHandler.bind(this)
        this.scrollHandlerBound = this.scrollHandler.bind(this)
        this.onNewSectionBound = this.onNewSection.bind(this)
        this.updateBound = this.update.bind(this)

        this.init()
    }


    init() {
        this.debug = new Debug()

        this.animationLoop = new AnimationLoop()
        this.animationLoop.on('tick', this.updateBound)

        this.renderSize = new RenderSize()
        this.renderSize.on('resize', this.resizeHandlerBound)

        if (this.renderSize.mobile) {
            this.debug.closeUI()
        }

        this.setupSectionBackground()

        // this.newSectionSound = new Audio('sounds/SectionWhoosh.mp3')

        this.assetManager = new AssetManager(assets)
        this.assetManager.on('ready', this.assetManagerReadyHandlerBound)
        this.assetManager.startLoading()
    }

    resizeHandler(info) {
        this.fovWidth = this.fovHeight * info.aspect
        this.backgroundPlane.material.uniforms.uAspect.value = info.aspect
    }

    touchEntropyHandler(info) {
        const divider = this.renderSize.mobile ? 50 : 70

        document.querySelectorAll('.container *:not(.no-fade)').forEach(e => {
            e.style.opacity = 1 - info.entropy / divider
        });
    }

    assetManagerReadyHandler(info) {

        this.fizzy1 = new FizzyParticles()
        this.fizzy1.load(this.assetManager.items.eyeblue)

        this.fizzy2 = new FizzyParticles()
        this.fizzy2.load(this.assetManager.items.eyebrowngreen)

        this.fizzy3 = new FizzyParticles()
        this.fizzy3.load(this.assetManager.items.eyegreen)

        this.fizzy4 = new FizzyParticles()
        this.fizzy4.load(this.assetManager.items.eyeyann)

        this.fizzy1.on('touch-entropy', this.touchEntropyHandlerBound)

        this.canvas.addEventListener('pointermove', this.pointerMoveHandlerBound)
        this.canvas.addEventListener('touchmove', this.pointerMoveHandlerBound)

        this.fizzy1.excite()

        this.initWebGL()

        this.scroll = new ScreenScroll(this.pageSections.length)
        this.scroll.on('scroll', this.scrollHandlerBound)
        this.scroll.on('newsection', this.onNewSectionBound)

        this.changeBackgroundColorHue(0)

        this.animationLoop.start()
    }

    onNewSection(info) {
        this.fizzy1.off('touch-entropy', this.touchEntropyHandlerBound)
        this.fizzy2.off('touch-entropy', this.touchEntropyHandlerBound)
        this.fizzy3.off('touch-entropy', this.touchEntropyHandlerBound)
        this.fizzy4.off('touch-entropy', this.touchEntropyHandlerBound)

        this.fizzy1.container.visible = false
        this.fizzy2.container.visible = false
        this.fizzy3.container.visible = false
        this.fizzy4.container.visible = false

        // this.newSectionSound.currentTime = 0
        // this.newSectionSound.volume = 0.1
        // this.newSectionSound.play()

        if (this.pageSections !== null) {
            const fizzy = this.pageSections[info.section]
            if (typeof fizzy !== "undefined" && typeof fizzy.excite === "function") {
                fizzy.container.visible = true
                fizzy.excite()
                fizzy.on('touch-entropy', this.touchEntropyHandlerBound)
            }
        }
    }

    pointerMoveHandler(event) {
        // Filter high rate pointer events (when using devtools) to keep consistant experience
        if (event.timeStamp - this.pointerTimestamp < 1000/60) {
            return
        }
        this.pointerTimestamp = event.timeStamp

        let source = event

        if (typeof TouchEvent !== "undefined" && event instanceof TouchEvent) {
            source = event.touches[0]
        }

        const r = event.target.getBoundingClientRect()
        const x =  ((source.clientX - r.x) / r.width) * 2 - 1
        const y = -((source.clientY - r.y) / r.height) * 2 + 1

        const pointer = new THREE.Vector2(x, y)

        if (typeof this.pageSections[this.scroll.currentSection].updateInteraction === "function") {
            this.pageSections[this.scroll.currentSection].updateInteraction(pointer)
        }
    }

    setupSectionBackground() {
        this.sectionBackgroundMesh = new THREE.Group()
    }

    scrollHandler(info) {
        this.changeBackgroundColorHue(info.pageScroll)
    }

    changeBackgroundColorHue(hue) {
        const color = new THREE.Color().setHSL((hue * 1.05 - 0.35), 0.4, 0.5)
        this.backgroundPlane.material.uniforms.uColor.value = color
    }

    initWebGL() {
        this.renderer = new Renderer()

        this.backgroundScene = new THREE.Scene()
        this.scene = new THREE.Scene()

        this.camera = new Camera(false)
        this.scene.add(this.camera.group)
        this.camera.perspective.position.z = 100
        this.camera.orthographic.position.z = 100

        this.fovHeight = 2 * this.camera.perspective.position.z * Math.tan((this.camera.perspective.fov * Math.PI) / 180 / 2)
        this.fovWidth = this.fovHeight * this.renderSize.aspect

        const bGeometry = new THREE.PlaneGeometry(2, 2)
        const bMaterial = new THREE.RawShaderMaterial({
            vertexShader: backgroundVS,
            fragmentShader: backgroundFS,
            transparent: true,
            uniforms: {
                uAspect: { value: this.renderSize.aspect },
                uColor: { value: new THREE.Color(1, 0.2, 0.45) }
            }
        })
        this.backgroundPlane = new THREE.Mesh(bGeometry, bMaterial)
        this.backgroundScene.add(this.backgroundPlane)

        this.sectionBMesh1 = this.sectionBackgroundMesh.clone()
        this.sectionBMesh2 = this.sectionBackgroundMesh.clone()
        this.sectionBMesh3 = this.sectionBackgroundMesh.clone()
        this.sectionBMesh4 = this.sectionBackgroundMesh.clone()

        this.scene.add(this.sectionBMesh1, this.sectionBMesh2, this.sectionBMesh3, this.sectionBMesh4)

        this.pageSections = [this.fizzy1, this.fizzy2, this.fizzy3, this.fizzy4]

        this.objectsDistance = 50
        let scale = this.fovHeight * 0.9

        if (this.renderSize.mobile === true) {
            scale = this.fovHeight * 0.45
        }

        this.sectionBMesh1.position.y = -this.objectsDistance * 0
        this.sectionBMesh2.position.y = -this.objectsDistance * 1
        this.sectionBMesh3.position.y = -this.objectsDistance * 2
        this.sectionBMesh4.position.y = -this.objectsDistance * 3

        this.sectionBMesh1.add(this.fizzy1.container)
        this.sectionBMesh2.add(this.fizzy2.container)
        this.sectionBMesh3.add(this.fizzy3.container)
        this.sectionBMesh4.add(this.fizzy4.container)

        this.fizzy1.container.position.z = 1
        this.fizzy2.container.position.z = 1
        this.fizzy3.container.position.z = 1
        this.fizzy4.container.position.z = 1

        this.fizzy1.scale(scale)
        this.fizzy2.scale(scale)
        this.fizzy3.scale(scale)
        this.fizzy4.scale(scale)

        this.fizzy1.container.visible = true
        this.fizzy2.container.visible = false
        this.fizzy3.container.visible = false
        this.fizzy4.container.visible = false

        // this.scene.add(this.fizzy1.container, this.fizzy2.container, this.fizzy3.container, this.fizzy4.container)
    }

    update(info) {
        this.trigger('beforeRender')

        this.fizzy1.update(info)
        this.fizzy2.update(info)
        this.fizzy3.update(info)
        this.fizzy4.update(info)

        // Update camera position with scroll info
        this.camera.perspective.position.y = -(this.scroll.scrollY / this.renderSize.height) * this.objectsDistance

        // Clear the screen
        this.renderer.clear()

        // Render the background scene
        this.renderer.render(this.backgroundScene, this.camera.orthographic)

        // Render the scene
        this.renderer.render(this.scene, this.camera.perspective)

        // Update debug
        this.debug.update()

        this.trigger('afterUpdate')
    }

    destroy() {
        this.animationLoop.off('tick')
        this.renderSize.off('resize')
        this.scroll.off('scroll')
        this.scroll.off('newsection')
        this.assetManager.off('ready')

        this.canvas.removeEventListener('pointermove', this.pointerMoveHandlerBound)
        this.canvas.removeEventListener('touchmove', this.pointerMoveHandlerBound)

        this.assetManagerReadyHandlerBound = null
        this.touchEntropyHandlerBound = null
        this.pointerMoveHandlerBound = null
        this.resizeHandlerBound = null
        this.scrollHandlerBound = null
        this.onNewSectionBound = null
        this.updateBound = null

        this.animationLoop.destroy()
        this.animationLoop = null

        this.camera.destroy()
        this.camera = null

        this.renderer.destroy()
        this.renderer = null

        this.pageSections.length = 0
        this.pageSections = null

        this.fizzy1.destroy()
        this.fizzy1 = null

        this.fizzy2.destroy()
        this.fizzy2 = null

        this.fizzy3.destroy()
        this.fizzy3 = null

        this.fizzy4.destroy()
        this.fizzy4 = null

        if (this.sectionBackgroundTexture !== null) {
            this.sectionBackgroundTexture.dispose()
            this.sectionBackgroundTexture = null
        }

        this.sectionBackgroundMesh.geometry.dispose()
        this.sectionBackgroundMesh.material.dispose()
        this.sectionBackgroundMesh = null

        this.sectionBMesh1.geometry.dispose()
        this.sectionBMesh1.material.dispose()
        this.sectionBMesh1 = null

        this.sectionBMesh2.geometry.dispose()
        this.sectionBMesh2.material.dispose()
        this.sectionBMesh2 = null

        this.sectionBMesh3.geometry.dispose()
        this.sectionBMesh3.material.dispose()
        this.sectionBMesh3 = null

        this.sectionBMesh4.geometry.dispose()
        this.sectionBMesh4.material.dispose()
        this.sectionBMesh4 = null

        // this.newSectionSound = null

        this.renderSize.destroy()
        this.renderSize = null

        this.assetManager.destroy()
        this.assetManager = null

        this.scroll.destroy()
        this.scroll = null

        this.debug.destroy()
        this.debug = null

        this.ambient = null
        this.dir = null
        this.spotLight.dispose()
        this.spotLight = null

        this.backgroundScene.remove(this.backgroundPlane)
        this.backgroundScene = null

        this.backgroundPlane.geometry.dispose()
        this.backgroundPlane.material.dispose()
        this.backgroundPlane = null

        this.scene = null
        this.canvas = null
    }
}