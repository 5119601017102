import { App } from "/App"
import { EventEmitter } from "/Utils/EventEmitter"

export class ScreenScroll extends EventEmitter {
    constructor(nSections) {
        super()

        this.app = new App()
        this.nSections = nSections

        this.scrollY = 0
        this.currentSection = 0

        this.scrollHandlerBound = this.scrollHandler.bind(this)

        this.init()
    }

    init() {
        window.addEventListener('scroll', this.scrollHandlerBound)
    }

    scrollHandler() {
        this.scrollY = window.scrollY

        const height = this.app.renderSize.height
        const newSection = Math.round(this.scrollY / height)

        if (newSection != this.currentSection) {
            this.currentSection = newSection
            this.trigger('newsection', [{section: this.currentSection}])
        }

        this.trigger('scroll', [{
            pageScroll: this.getPageScroll()
        }])
    }

    getPageScroll() {
        return this.scrollY / (this.app.renderSize.height * (this.nSections))
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollHandlerBound)
        this.scrollHandlerBound = null

        this.app = null
    }
}
