export default [
    // Fizzy images
    {
        name: 'eyeyann',
        type: 'Texture',
        path: 'textures/fizzy/eye-yann.png',
        license: '',
        author: 'yann@eye.studio'
    },
    {
        name: 'eyebrowngreen',
        type: 'Texture',
        path: 'textures/fizzy/eye-brown-green.png',
        license: '',
        author: 'https://www.pexels.com/@/'
    },
    {
        name: 'eyegreen',
        type: 'Texture',
        path: 'textures/fizzy/eye-green.png',
        license: '',
        author: 'https://www.pexels.com/@wendelmoretti/',
    },
    {
        name: 'eyeblue',
        type: 'Texture',
        path: 'textures/fizzy/eye-blue.png',
        license: '',
        author: ''
    }
]
