import * as THREE from 'three'
import { App } from '../App.js'

import gsap from 'gsap'

import fizzyVS from '/shaders/fizzy.vert.glsl'
import fizzyFS from '/shaders/fizzy.frag.glsl'
import { TouchTexture } from '../Interaction/TouchTexture.js'
import { EventEmitter } from '../Utils/EventEmitter.js'

export class FizzyParticles extends EventEmitter {
    constructor() {
        super()
        this.app = new App()

        this.touchTexture = new TouchTexture()

        this.container = null
        this.mesh = null
        this.geometry = null
        this.material = null
        this.texture = null

        this.nParticles = 0
        this.pVisible = null
        this.nVisibles = 0

        this.interactionOverlay = null
        this.raycaster = null

        this.ui = null
    }

    static LUMA_THRESHOLD = 45

    load(texture) {
        this.texture = texture

        this.texture.minFilter = THREE.LinearFilter
        this.texture.magFilter = THREE.LinearFilter

        this.width = this.texture.image.width
        this.height = this.texture.image.height

        this.nParticles = this.width * this.height

        this.pVisible = new Array(this.nParticles).fill(true)
        this.nVisibles = this.nParticles

        this.initVisibility()
        this.initObjects()
    }

    initVisibility() {
        // Get imaga data from the texture image
        // Only create a particle for points over the luminance threshold
        const imgCanvas = document.createElement('canvas')
        imgCanvas.width = this.width
        imgCanvas.height = this.height

        const ctx = imgCanvas.getContext('2d')
        ctx.scale(1, -1)
        ctx.drawImage(this.texture.image, 0, 0, this.width, this.height * -1)

        const imgData = ctx.getImageData(0, 0, this.width, this.height)
        const imgPixels = Float32Array.from(imgData.data)

        // Count pixels with a sufficient luminance
        for (let i = 0; i < this.nParticles; i++) {
            const i4 = i * 4
            const luma = imgPixels[i4 + 0] * 0.21 + imgPixels[i4 + 1] * 0.71 + imgPixels[i4 + 2] * 0.07
            this.pVisible[i] = luma > FizzyParticles.LUMA_THRESHOLD
            if (luma > FizzyParticles.LUMA_THRESHOLD) {
                this.nVisibles++
            }
        }
    }

    /*
     * Create the instanced buffer geometry
     * with some constant attributes for every tile : BufferAttribute
     * and some attributes specific to each tile : InstancedBufferAttribute
     */
    initGeometry() {
        this.geometry = new THREE.InstancedBufferGeometry()

        // Constant attributes
        const positionAttr = new THREE.BufferAttribute(new Float32Array(4 * 3), 3)
        positionAttr.setXYZ(0, -0.5,  0.5, 0)
        positionAttr.setXYZ(1,  0.5,  0.5, 0)
        positionAttr.setXYZ(2,  0.5, -0.5, 0)
        positionAttr.setXYZ(3, -0.5, -0.5, 0)
        this.geometry.setAttribute('position', positionAttr)

        const uvAttr = new THREE.BufferAttribute(new Float32Array(4 * 2), 2, false)
        uvAttr.setXYZ(0, 0, 1)
        uvAttr.setXYZ(1, 1, 1)
        uvAttr.setXYZ(2, 1, 0)
        uvAttr.setXYZ(3, 0, 0)
        this.geometry.setAttribute('uv', uvAttr)

        const indexAttr = new THREE.BufferAttribute(new Uint16Array([ 0, 2, 1, 0, 3, 2 ]), 1)
        this.geometry.setIndex(indexAttr)

        // Tile speficic attributes
        const pPositions = new Float32Array(this.nVisibles * 3)
        const pIndices = new Uint16Array(this.nVisibles)
        const pRandomScales = new Float32Array(this.nVisibles)
        const pTouchAngles = new Float32Array(this.nVisibles)

        for (let i = 0, p = 0; i < this.nParticles; i++) {
            // Discard every point darker than the threshold
            if (this.pVisible[i] === false) {
                continue
            }

            pPositions[p * 3 + 0] = i % this.width // [0 .. 1279]
            pPositions[p * 3 + 1] = Math.floor(i / this.width) // [0 .. 1919]
            pPositions[p * 3 + 2] = 0

            pIndices[p] = i
            pRandomScales[p] = Math.random() * 15
            pTouchAngles[p] = Math.random() * Math.PI

            p++
        }

        this.geometry.setAttribute('aPPosition', new THREE.InstancedBufferAttribute(pPositions, 3, false))
        this.geometry.setAttribute('aPScale', new THREE.InstancedBufferAttribute(pRandomScales, 1, false))
        this.geometry.setAttribute('aPIndex', new THREE.InstancedBufferAttribute(pIndices, 1, false))
        this.geometry.setAttribute('aPTouchAngle', new THREE.InstancedBufferAttribute(pTouchAngles, 1, false))
    }

    initMaterial() {
        this.material = new THREE.RawShaderMaterial({
            transparent: true,
            side: THREE.DoubleSide,
            depthTest: false,
            depthWrite: false,
            vertexShader: fizzyVS,
            fragmentShader: fizzyFS,
            uniforms: {
                uOpacity: { value: 1.0 },
                uBlackAndWhite: { value: false },
                uContrast: { value: 0.4 },
                uSaturation: { value: 1 },
                uLCrop: { value: 0.0 },
                uHCrop: { value: 0.9 },
                uExposure: { value: 1.12 },
                uTime: { value: 0 },
                uSize: { value: 20 },
                uDepth: { value: 50.0 },
                uDisplaceSpeed: { value: 0.05 },
                uDOF: { value: 0.85 },
                uTexture: { value: this.texture },
                uTextureSize: { value: new THREE.Vector2(this.width, this.height) },
                uDispersion: { value: 2.0 },
                uTouchTexture: { value: this.touchTexture.texture }
            }
        })

        if (this.app.debug.active) {
            this.ui = []
            this.app.debug.ui.add(this.touchTexture.points, 'length').min(0).max(2000).step(1).name("Entropy").listen()
            this.app.debug.ui.add(this.touchTexture, 'radius').min(0).max(10).step(0.01).name("Touch Radius")
            // this.app.debug.ui.add(this.material.uniforms.uOpacity, 'value').min(0).max(1).step(0.001).name("Opacity").listen()
            this.app.debug.ui.open()
            // const gFImage = this.app.debug.ui.addFolder('Image Controls').close()
            // gFImage.add(this.material.uniforms.uBlackAndWhite, 'value').name("Black and white")
            // gFImage.add(this.material.uniforms.uExposure, 'value').min(0.01).max(2).step(0.001).name("Exposure")
            // gFImage.add(this.material.uniforms.uContrast, 'value').min(0).max(0.6).step(0.001).name("Constrast").listen()
            // gFImage.add(this.material.uniforms.uSaturation, 'value').min(0).max(1).step(0.001).name("Saturation").listen()
            // gFImage.add(this.material.uniforms.uLCrop, 'value').min(0).max(1).step(0.001).name("Black crop")
            // gFImage.add(this.material.uniforms.uHCrop, 'value').min(0).max(1).step(0.001).name("White crop")

            // const gFParticles = this.app.debug.ui.addFolder('Particles')
            // gFParticles.add(this.material.uniforms.uSize, 'value').min(0).max(20).step(0.1).name("Particle size")
            // gFParticles.add(this.material.uniforms.uDisplaceSpeed, 'value').min(0).max(1).step(0.001).name("Displace speed")
            // gFParticles.add(this.material.uniforms.uDepth, 'value').min(0).max(100).step(0.1).name("Displace depth")
            // gFParticles.add(this.material.uniforms.uDOF, 'value').min(0).max(3).step(0.001).name("Depth of Field")
            // this.app.debug.ui.add(this.material.uniforms.uDOF, 'value').min(0).max(3).step(0.001).name("Depth of Field").listen()

            // const gFInteraction = this.app.debug.ui.addFolder('Interaction').close()
            // gFInteraction.add(this.material.uniforms.uDispersion, 'value').min(0).max(10).step(0.01).name("Dispersion")
            // gFInteraction.add(this.touchTexture, 'ttl').min(0).max(4).step(0.01).name("Duration")
            // gFInteraction.add(this.touchTexture, 'radius').min(0).max(10).step(0.01).name("Spread")

            // this.ui.push(gFParticles, gFImage, gFInteraction)
        }
    }

    initObjects() {
        this.container = new THREE.Object3D()
        this.container.userData.instance = this

        this.initGeometry()
        this.initMaterial()

        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.x = 0
        this.container.add(this.mesh)

        const iGeometry = new THREE.PlaneGeometry(this.width, this.height, 32, 32)
        const iMaterial = new THREE.MeshBasicMaterial({
            transparent:true,
            opacity:0.0,
            depthTest: false,
            depthWrite: false
        })
        this.interactionOverlay = new THREE.Mesh(iGeometry, iMaterial)
        this.mesh.add(this.interactionOverlay)
        this.raycaster = new THREE.Raycaster()
    }

    scale(fovHeight) {
        const scale = fovHeight / this.height
        this.container.scale.set(scale, scale, 1)
    }

    excite() {
        this.material.uniforms.uDispersion.value = 4
        gsap.to(
            this.material.uniforms.uDispersion, {
                duration: 1.5,
                ease: 'circ.out',
                value: 2
            }
        )

        this.touchTexture.startRamp(5)
    }


    updateInteraction(pointer) {
        this.raycaster.setFromCamera(pointer, this.app.camera.perspective)
        const intersect = this.raycaster.intersectObject(this.interactionOverlay, false)
        if (intersect.length > 0) {
            this.touchTexture.addPoint(intersect[0].uv)
        }
    }

    update(info) {
        const elapsed = info.elapsed
        const delta = info.delta

        // Update touch texture
        this.touchTexture.update(delta)

        if (this.material !== null) {
            this.material.uniforms.uTime.value = elapsed * 0.8

            // Anim opacity over 10 seconds with 10% variations
            const opacityAnim = (Math.sin(elapsed * 2 * Math.PI / 10) * 0.5 + 0.5) * 0.1

            // Touch factor to impact render based on user action
            let userActionAnimControl = Math.min(1, this.touchTexture.entropy / 200)

            this.material.uniforms.uDOF.value = 20 * userActionAnimControl
            this.material.uniforms.uSize.value = (1 - userActionAnimControl * 0.7) * 20
            this.material.uniforms.uOpacity.value = Math.max(0.03, Math.min(1, userActionAnimControl + opacityAnim))
        }

        this.trigger('touch-entropy', [{entropy:this.touchTexture.entropy}])
    }

    clearScene() {
        if (this.geometry !== null) {
            this.geometry.dispose()
            this.geometry = null
        }

        if (this.material !== null) {
            this.material.dispose()
            this.material = null
        }

        if (this.container !== null) {
            this.container.remove(this.mesh)
            this.container = null
        }

        if (this.texture !== null) {
            this.texture.dispose()
            this.texture = null
        }

        this.mesh.remove(this.interactionOverlay)
        this.interactionOverlay.geometry.dispose()
        this.interactionOverlay.material.dispose()
        this.interactionOverlay = null

        this.mesh = null
    }

    destroy() {
        this.clearScene()

        this.container.userData.instance = null

        if (this.ui !== null) {
            for (const folder of this.ui) {
                folder.destroy()
            }

            this.ui.length = 0
            this.ui = null
        }

        this.pVisible.length = 0
        this.pVisible = null

        this.raycaster = null

        this.touchTexture = null
        this.app = null
    }
}
