import { App } from './App'

import './scss/style.scss'
import './scss/button.scss'
import './scss/modalform.scss'


['#cta', '.contact-modal'].forEach((sel) => {
    document.querySelector(sel).style.pointerEvents = "all"
})

document.getElementById('frmContactForm').addEventListener("change", (e) => {
    const isModal = e.target.checked

    const moverlay = document.querySelector('.modal-overlay')
    if (isModal) {
        moverlay.classList.add('mo-visible')
    }
    else {
        moverlay.classList.remove('mo-visible')
    }
})

window.onbeforeunload = function () {
    window.scrollTo(0, 0)
}

// Function to validate email addresses
const isValidEmail = (email) => {
    // Define the JS Regex pattern for a valid email address
    var emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/

    // Test the email against the pattern and return the result (true or false)
    return emailRegex.test(email)
}

const sendEmail = (email, name, content) => {
    // console.log(`send email reply-to ${email} from ${name}, message: ${content}`)
    const body = `<pre style='font-family:Arial;'>
    <h1>Nom du contact</h1>
    <p>${name}</p>
    <h1>Email du contact</h1>
    <p>${email}</p>
    <h1>Message</h1>
    <p>${content}</p>
    </pre>`

    Email.send({
        SecureToken : "6478dbb4-d2c7-4ac5-823a-a0b7a50ae7e5",
        To : 'yann@eye.studio',
        From : `yann@eye.studio`,
        Subject : "eye.studio new contact",
        Body : body
    }).then(
      message => {
          if (message.toLowerCase() === 'ok') {
                const thanks = document.querySelector('.contact-form > p')
                const form = document.querySelector('.contact-form > form')

                form.style.display = 'none'
                thanks.style.display = 'block'

                document.querySelector('.contact-form').addEventListener('click', () => {
                    document.getElementById('frmContactForm').checked = false
                    const moverlay = document.querySelector('.modal-overlay')
                    moverlay.classList.remove('mo-visible')
                    thanks.style.display = 'none'
                })
            }
        }
    )
}

document.querySelector(".btn-send").addEventListener('click', (event) => {
    const email = document.getElementById("txtEmail").value

    if (!isValidEmail(email)) {
      event.preventDefault()
      alert('Email address invalid: ' + email)
      return
    }

    const name = document.getElementById("txtFullName").value
    const content = document.getElementById("txtContent").value

    sendEmail(name, email, content)
})

const canvas = document.querySelector('canvas.webgl')
const app = new App(canvas)

